<template>
    <ODatePicker ref="elRef" v-model="model" :utc="column.utc" class="override text-truncate d-flex flex-1" inputClassName="text-truncate rounded-0"
        :format="_format" :date="column.type === 'date'" :timepicker="_timepicker" :teleport="true" :openOnIconClick="_openOnIconClick"
        :outputTimeString="outputTimeString"/>
</template>

<script lang="ts">
import type { DataColumn } from 'o365-datagrid';

export interface IProps {
    column: DataColumn,
    format?: string,
    timepicker?: boolean,
    openOnIconClick?: boolean
};
</script>

<script setup lang="ts">
import ODatePicker from './Inputs.DatePicker.vue';
import { ref, computed } from 'vue';

const props = defineProps<IProps>();

const model = defineModel<any>();
const elRef = ref(null);

const _format = computed(() => {
    return props.format ?? props.column.format;
});

const _timepicker = computed(() => {
    return props.column?.type == 'time' || (props.timepicker ?? props.column?.type === 'datetime') && _format.value !== 'Short Date';
});

const outputTimeString = computed(() => {
    return props.column?.type == 'time';
})

const _openOnIconClick = computed(() => {
    return props.openOnIconClick ? true : false;
});

function activateEditor() {
       elRef.value?.activateEditor();
}

defineExpose({ elRef, activateEditor });
</script>